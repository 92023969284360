<section class="animated fadeIn">

    <div class="row">
        <i class="fas fa-times close-mark text-end text-secondary-800"
            (click)="outFlipCardType.emit(card().settings.isSummary || false ? cardTypes.summary : cardTypes.main)"></i>
    </div>

    <div>
        <h4 class="text-center h3-bold head-font text-secondary-800">
            Product subscription
        </h4>
        <p class="text-secondary-700">
            When would you like to receive your subscription item(s)?
        </p>

        @if(frequencies().length) {
        <mat-form-field class="rounded-selector mb-3 d-flex" subscriptSizing="dynamic">
            <mat-label> Frequency </mat-label>
            <mat-select (selectionChange)="onChangeFrequency($event)" [value]="selectedFrequency?.id">
                @for(freq of frequencies(); track $index){
                <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        }

        @if (!isTemporaryRouteChange()) {
        <mat-checkbox [checked]="true" class="mb-3" (change)="onInitiateSubscriptionImmediatly($event)">
            <span class="text-secondary-700 h7-regular">
                Initiate my subscription immediately
            </span>
        </mat-checkbox>
        }
        @if (initiateSubscriptionImmediatly()) {
        <app-empty-message [width100]="true" [mt0]="true"
            [message]="'Next Delivery: ' + deliveryZoneInfo()?.readeableDeliveryDate"></app-empty-message>
        } @else {
        <mat-form-field class="w-100 mb-3 animated fadeIn">
            <mat-label>Start subscription date</mat-label>
            <input matInput [min]="minDate()" [matDatepicker]="startSubscription" [matDatepickerFilter]="dateFilter"
                readonly (dateChange)="onDateChange($event)" (click)="startSubscription.open()">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="startSubscription"></mat-datepicker-toggle>
            <mat-datepicker [yPosition]="'below'" #startSubscription disabled="false"></mat-datepicker>
        </mat-form-field>
        }
    </div>

    <div class="card-footer p-0 mt-1 mt-auto">
        <div class="row row-gap-2">
            <div class="col">
                <button type="button"
                    (click)="outFlipCardType.emit(card().settings.isSummary || false ? cardTypes.summary : cardTypes.main)"
                    class="btn btn-outline-secondary d-flex gap-2 align-items-center h5-semibold head-font">
                    Cancel
                </button>
            </div>
            <div class="col">
                <button type="button" (click)="outAddProductToCart.emit({isSubscription: true, event: $event})"
                    [disabled]="!selectedFrequency || (!this.initiateSubscriptionImmediatly() && !this.selectedDateSubscription)"
                    class="btn btn-secondary d-flex gap-2 align-items-center h5-semibold head-font">
                    Subscribe
                </button>
            </div>
        </div>
    </div>

</section>