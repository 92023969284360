<div class="product-card" [class.product-card--subscription]="cardType() == cardTypes.subscribe"
    [class.product-card--summary]="cardType() == cardTypes.summary"
    [class.product-card--check-address]="cardType() == cardTypes.checkAddress" [class.seasonal-product]="true">

    <div class="product-card__body">

        @switch (cardType()) {

        @case (cardTypes.resume) {
        @let productResume = cardResume().product;
        @if (productResume) {
        <app-product-card-v2-resume [product]="productResume"
            [isFromWelcome]="isFromWelcome()"></app-product-card-v2-resume>
        }
        }

        @case (cardTypes.summary) {

        <app-product-card-v2-summary [product]="product()" [isOutOfStock]="isOutOfStock()"
            [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [hasNotify]="hasNotify" [isGiftCard]="isGiftCard()"
            [productAttributes]="productAttributes" [selectedAttribute]="selectedAttribute()"
            [selectedPackage]="selectedPackage()" [selectedAttributeCardConfig]="selectedAttributeCardConfig()"
            [wasValueHigher10]="wasValueHigher10" [newQuantity]="newQuantity()"
            [totalStockAvailable]="totalStockAvailable()" [availableQuantities]="availableQuantities()"
            [stockSignal]="stock()" [isDisabledActionButtons]="isDisabledActionButtons()"
            [isDisabledSubscribeButton]="isDisabledSubscribeButton()" [existsInOrder]="existsInOrder()"
            [isPreOrderedVariant]="isPreOrderedVariant()" (outFlipCardType)="flipCardType($event)"
            (outModalCheckAddress)="openModalCheckAddress()"
            (outOnAttributeChange)="onAttributeChange($event.id, $event.event)"
            (outOnPackageChanged)="onPackageChanged($event)" (outOnInputQuantityChange)="onInputQuantityChange($event)"
            (outOnKeyUp)="onKeyUp($event)" (outOnQuantityChange)="onQuantityChange($event)"
            (outAddProductToCart)="addProductToCart($event.isSubscription, $event.event)"
            (outOpenModalCheckAddress)="openModalCheckAddress()">

        </app-product-card-v2-summary>

        } @case (cardTypes.main) {

        <app-product-card-v2-main [card]="card()" [isOutOfStock]="isOutOfStock()"
            [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [hasNotify]="hasNotify"
            [productAttributes]="productAttributes" [selectedAttribute]="selectedAttribute()"
            [selectedPackage]="selectedPackage()" [selectedAttributeCardConfig]="selectedAttributeCardConfig()"
            [wasValueHigher10]="wasValueHigher10" [newQuantity]="newQuantity()"
            [totalStockAvailable]="totalStockAvailable()" [availableQuantities]="availableQuantities()"
            [stockSignal]="stock()" [isDisabledActionButtons]="isDisabledActionButtons()"
            [isDisabledSubscribeButton]="isDisabledSubscribeButton()" [existsInOrder]="existsInOrder()"
            [isPreOrderedVariant]="isPreOrderedVariant()" [productId]="productId()" [isFromWelcome]="isFromWelcome()"
            (outFlipCardType)="flipCardType($event.type)" (outModalCheckAddress)="openModalCheckAddress()"
            (outOnAttributeChange)="onAttributeChange($event.id, $event.event)"
            (outOnPackageChanged)="onPackageChanged($event)" (outOnInputQuantityChange)="onInputQuantityChange($event)"
            (outOnKeyUp)="onKeyUp($event)" (outOnQuantityChange)="onQuantityChange($event)"
            (outAddProductToCart)="addProductToCart($event.isSubscription, $event.event)"
            (outOpenModalCheckAddress)="openModalCheckAddress()"
            (outOpenModalWhatsInside)="openModalWhatsInside($event)">
        </app-product-card-v2-main>

        } @case (cardTypes.notify) {

        <app-product-card-v2-notify [productId]="productId()" (outFlipCardType)="flipCardType($event)">
        </app-product-card-v2-notify>

        } @case (cardTypes.notified) {

        <app-product-card-v2-notified (outFlipCardType)="flipCardType($event)"></app-product-card-v2-notified>

        } @case (cardTypes.subscribe) {

        <app-product-card-v2-subscribe [card]="this.card()" (outFlipCardType)="flipCardType($event)"
            (onSelectedFrequencyChange)="selectedFrequency.set($event)"
            (onStartDateChange)="selectedDateSubscription.set($event)"
            (outAddProductToCart)="addProductToCart($event.isSubscription, $event.event)"
            (onInitiateSubscriptionImmediatlyChanged)="initiateSubscriptionImmediatly.set($event)">
        </app-product-card-v2-subscribe>

        }

        }

    </div>

</div>