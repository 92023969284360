<section class="d-flex flex-column flex-grow-1">

    <div class="product-card__img-wrap" [ngClass]="{'card-img active-filter-blur': isOutOfStock()}">

        <!-- Like -->
        @if(hasSession()) {
        <section class="product-card__pre-head">
            @if (isFavorite()) {
            <span (click)="handleFavoriteChange()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#ffffff">
                    <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" />
                </svg>
            </span>
            } @else {
            <span (click)="handleFavoriteChange()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#ffffff">
                    <path
                        d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
                </svg>
            </span>
            }
        </section>
        }

        <!-- Sale Badge -->
        @if(showSaleBadge()) {
        <div class="sale-badge">
            <span class="material-symbols-outlined">
                percent
            </span>
        </div>
        }

        <a [routerLink]="productRouterLink()">
            @defer(on idle) {
            <img [src]="productImg()" class="product-card__img" (error)="handleImageError($event)">
            } @placeholder {
            <div class="product-card__img"></div>
            }
        </a>



        <!-- Hovered Button over main image -->
        @if (showHoveredButton()) {
        <div class="info-badge">
            <button type="button" class="rolling-info active" (click)="outOpenModalWhatsInside.emit($event)">
                @if( selectedAttributeCardConfig().isSubscription && existsInOrder().subscription &&
                bundleModifyType === bundleModifyTypes.outOfOrder) {
                Subscribed
                }
                @else {
                What's inside
                }
            </button>
        </div>
        }

        @if (showMarketClosedSection()) {
        <div class="d-grid position-absolute bottom-0 w-100">
            <div class="mb-0 w-100 alert py-2 px-3 alert-warning d-flex align-items-center animated fadeIn"
                role="alert">
                @if (isMobile()) {
                <div class="h8-regular text-center">
                    Available <b>on {{marketStatus().openingDay | titlecase}}</b>
                </div>
                } @else {
                <div class="h7-regular">
                    You'll be <b>able to add</b> items to your cart on <b>{{marketStatus().openingDay |
                        titlecase}}</b> Morning
                </div>
                }
            </div>
        </div>
        }

        <!-- Overlay -->
        @if (showInOrderOverlay()) {
        <div class="in-order animated fadeIn">
            <span>{{existsInOrder().totalQuantity}} IN</span>
            <span>ORDER</span>
        </div>
        }

    </div>

    <!-- Card Header - Title and Subtitle -->
    <div class="product-card__head" [ngClass]="{'card-img active-filter-blur': isOutOfStock()}">
        <a [routerLink]="productRouterLink()">
            <h3 class="product-card__title" [ngbTooltip]="productNameTooltip()">
                {{productName()}}
            </h3>
        </a>
        <h5 class="product-card__subtitle">
            {{producerName()}}
        </h5>
    </div>

    @if (showOOSComponent()) {
    <app-out-of-stock [class.oosperQuantity]="isOutOfStockPerQuantity()"
        [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [isOutOfStock]="isOutOfStock()" [hasNotify]="hasNotify()"
        [isFromSummary]="false" (outFlipCardType)="outFlipCardType.emit({type: $event})"
        (outModalCheckAddress)="outModalCheckAddress.emit()"></app-out-of-stock>
    }
    <section [class.d-none]="isOutOfStock()">
        <div class="product-card__meta">

            <div class="product-card__meta--item is-col-2">
                <div class="d-grid">
                    <div class="d-flex gap-1 d-flex gap-1 flex-row align-items-center">
                        @if(!selectedAttributeCardConfig().isABundle &&
                        !selectedAttributeCardConfig().bundle?.items?.length &&
                        selectedAttributeCardConfig().bundle?.priceRange) {
                        <h5 class="product-card__price">{{ selectedAttributeCardConfig().bundle?.priceRange }}
                        </h5>
                        } @else {
                        <h5 class="product-card__price">{{ selectedAttributeCardConfig().price | currency }}
                        </h5>
                        }
                        @if(selectedAttributeCardConfig().hasDiscount) {
                        <small
                            class="product-card__price--line-through text-body-tertiary fw-bold text-decoration-line-through">
                            {{selectedAttributeCardConfig().originalPrice | currency}}
                        </small>
                        }
                    </div>
                </div>

                <!-- Quantity buttons -->
                <div class="col quantity">
                    @if (wasValueHigher10()) {
                    <small class="how-many-label d-none d-sm-block">How many?</small>
                    }
                    <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                        @if( wasValueHigher10() || newQuantity() >= 10 || (newQuantity() >= 10 &&
                        wasValueHigher10()) ){
                        <input type="text" matInput appNumberRestriction [minNumber]="1"
                            [maxNumber]="totalStockAvailable()" (change)="outOnInputQuantityChange.emit($event)"
                            (keyup)="outOnKeyUp.emit($event)" [value]="newQuantity()">
                        }
                        @else{
                        <mat-select [value]="newQuantity()" (selectionChange)="outOnQuantityChange.emit($event)">
                            @for(q of availableQuantities(); track $index){
                            <mat-option [value]="q.val">{{ q.name }}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                </div>

            </div>

            <div class="row">
                <!-- Product Customizations  -->
                <div class="col-12 d-flex gap-1">
                    @if (card().settings.showAttributes) {
                    @if (productAttributes().length) {
                    @for (attr of productAttributes(); track $index) {
                    <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                        <mat-label class="text-capitalize">{{attr.name.toLowerCase()}}</mat-label>
                        @if (card().product.variantId || attr.values.length === 1) {
                        <input matInput type="text" readonly="true" [value]="selectedAttribute()?.value.name">
                        } @else {
                        <mat-select [value]="selectedAttribute()?.value?.id || ''"
                            (selectionChange)="outOnAttributeChange.emit({id: attr.id, event: $event})">
                            @for (item of attr.values; track $index) {
                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                    }
                    }
                    @if (card().product.packages?.length) {
                    <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                        <mat-label class="text-capitalize">Package</mat-label>
                        @if (card().product.packages?.length === 1) {
                        <input matInput type="text" readonly="true" [value]="selectedPackage()?.name">
                        } @else {
                        <mat-select [value]="selectedPackage().id"
                            (selectionChange)="outOnPackageChanged.emit($event.value)">
                            @for (item of card().product.packages; track $index) {
                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                    }
                    }
                </div>
            </div>

            <p class="product-card__info">
                {{productDisplay()}}
            </p>

        </div>


        <!-- Action button -->
        @if(!isFromWelcome()) {
        <div class="product-card__action">
            <div class="d-flex">
                @if (hasSession()) {
                @if (selectedAttributeCardConfig().isSubscription) {
                @if (card().product.bundle?.items?.length) {
                <div class="d-flex gap-2 w-100">
                    @if(stockSignal().size) {
                    @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
                    <button type="button" (click)="outAddProductToCart.emit({isSubscription: false, event: $event})"
                        [disabled]="isDisabledActionButtons()"
                        class="btn btn-outline-secondary d-flex gap-2 align-items-center head-font">
                        @if (isMobile()) {
                        <i class="fas fa-plus"></i>
                        } @else {
                        {{getButtonText({isBundle: true, isSubscription: false})}}
                        }
                    </button>
                    }
                    <button type="button" [disabled]="isDisabledSubscribeButton()"
                        (click)="outFlipCardType.emit({type: cardTypes.subscribe})"
                        class="btn btn-secondary d-flex gap-2 align-items-center head-font">
                        {{getButtonText({isBundle: true, isSubscription: true})}}
                    </button>
                    } @else {
                    <p class="placeholder-glow mb-0 w-100">
                        <span
                            class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                            style="height: 36px;"></span>
                    </p>
                    }
                </div>
                }
                @else {
                <div class="d-flex gap-2 w-100">
                    @if(stockSignal().size) {
                    @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
                    <button type="button" (click)="outAddProductToCart.emit({isSubscription:false, event: $event})"
                        [disabled]="isDisabledActionButtons()"
                        class="btn btn-outline-secondary d-flex gap-2 align-items-center head-font">
                        @if (isMobile()) {
                        <i class="fas fa-plus"></i>
                        } @else {
                        {{getButtonText({isBundle: false, isSubscription: false})}}
                        }
                    </button>
                    }
                    <button type="button" [disabled]="isDisabledSubscribeButton()"
                        (click)="outFlipCardType.emit({type: cardTypes.subscribe})"
                        class="btn btn-secondary d-flex gap-2 align-items-center head-font">
                        {{getButtonText({isBundle: false, isSubscription: true})}}
                    </button>
                    } @else {
                    <p class="placeholder-glow mb-0 w-100">
                        <span
                            class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                            style="height: 36px;"></span>
                    </p>
                    }
                </div>
                }
                }
                @else {
                @if(stockSignal().size && selectedAttributeCardConfig().isALaCarte) {
                <button type="button" (click)="outAddProductToCart.emit({isSubscription: false, event: $event})"
                    [disabled]="isDisabledActionButtons() || (!card().product.preOrder && !marketStatus().isOpen)"
                    class="btn btn-secondary d-flex gap-2 align-items-center head-font"
                    [class.btn-outline-secondary]="existsInOrder().common || isPreOrderedVariant()">
                    {{getButtonText({isBundle: false, isSubscription: false})}}
                </button>
                } @else {
                <p class="placeholder-glow mb-0 w-100">
                    <span
                        class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                        style="height: 36px;"></span>
                </p>
                }
                }
                }
                @else {
                <button type="button" (click)="outOpenModalCheckAddress.emit()"
                    class="btn btn-secondary d-flex gap-2 align-items-center head-font">
                    Add To Delivery
                </button>
                }
            </div>
        </div>
        } @else {
        <div class="product-card__action">
            <button type="button" (click)="outFlipCardType.emit({type: cardTypes.subscribe})"
                class="btn btn-secondary d-flex gap-2 align-items-center head-font w-100">
                Customize box
            </button>
        </div>
        }
    </section>


    <!-- Tags Icons -->
    <section class="product-card__tags">
        @for (tag of productTags() || []; track $index) {
        <div>
            {{tag.name}}
        </div>
        }
    </section>

</section>