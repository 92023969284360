import { afterNextRender, Component, computed, inject, input, InputSignal, OnInit, output, signal, Signal, WritableSignal } from '@angular/core';
import { CardTypes, ProductCard } from '../../product-card/product-card.types';
import { environment } from '../../../../environments/environment';
import { BundleModifyTypes } from '../../types/flows-config.types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Item } from '../../types/common.types';
import { SignalsStoreService } from '../../signals-store.service';
import { EmptyMessageComponent } from '../../empty-message/empty-message.component';
import { DeliveriesService } from '../../../settings/account/deliveries/deliveries.service';
import { FormattedDeliveryInformation } from '../../../settings/account/deliveries/intarfaces';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { formatSubscriptionDate, getWeekNumberFromDate } from '../../utils/formatting';
import { Session } from '../../types/session.type';
import { LocalStorageService } from '../../local-storage.service';
import { LOCALSTORAGE_KEYS } from '../../constants/databases';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-product-card-v2-subscribe',
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    CommonModule,
    MatCheckboxModule,
    EmptyMessageComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule
  ],
  templateUrl: './product-card-v2-subscribe.component.html',
  styleUrl: './product-card-v2-subscribe.component.scss'
})
export class ProductCardV2SubscribeComponent implements OnInit {

  //#region Injections
  #signalsStoreService = inject(SignalsStoreService);
  #deliveriesService = inject(DeliveriesService);
  #localStorageService = inject(LocalStorageService);
  //#endregion

  //#region Inputs:
  card: InputSignal<ProductCard> = input.required();
  //#endregion

  //#region Outputs:
  outFlipCardType = output<CardTypes>();
  outAddProductToCart = output<{ isSubscription: boolean, event: any }>();
  onSelectedFrequencyChange = output<Item>();
  onStartDateChange = output<string>();
  onInitiateSubscriptionImmediatlyChanged = output<boolean>();
  //#endregion

  //#region Signals:
  isTemporaryRouteChange: WritableSignal<boolean> = signal(false);
  initiateSubscriptionImmediatly: WritableSignal<boolean> = signal(true);
  minDate: WritableSignal<Date> = signal(new Date());
  weekTemporaryRouteChanges: WritableSignal<number[]> = signal([]);
  //#endregion

  //#region Computed:
  frequencies: Signal<any[]> = computed(() => {
    const freqs = this.#signalsStoreService.frequencies() || []
    if (freqs.length) {
      this.selectedFrequency = freqs[0];
      this.onSelectedFrequencyChange.emit(this.selectedFrequency);
    }

    return freqs;
  });
  deliveryZoneInfo: Signal<FormattedDeliveryInformation | null> = computed(() => this.#deliveriesService.deliveryZoneInfo());
  //#endregion

  //#region Properties:
  cardTypes = CardTypes;
  bundleModifyTypes = BundleModifyTypes;
  bundleModifyType = environment.config.flows.bundles.modifyType;
  selectedFrequency!: Item | undefined;
  deliveryDay: string = '';
  selectedDateSubscription!: any;

  dateFilter = (date: Date | null) => {
    if (!date) {
      return false;
    }

    if (!this.deliveryDay) return true;

    const numberWeek = getWeekNumberFromDate(date);

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const index = daysOfWeek.indexOf(this.deliveryDay);

    // If the date is in the week of route temporary change, disable it.
    if (this.weekTemporaryRouteChanges() && this.weekTemporaryRouteChanges().includes(numberWeek))
      return false;

    return date.getDay() === index;
  }
  //#endregion


  constructor() {
    afterNextRender(() => this.#validateTemporaryRoute());
  }

  ngOnInit(): void {
    const session: Session | null = this.#localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (session?.deliveryInfo) {
      this.deliveryDay = session.deliveryInfo.deliveryDay;
      const [year, month_str, day] = session.deliveryInfo.deliveryDate.split('-');
      const month = (+month_str) - 1;
      const aux_date = new Date(+year, month, (+day + 1));
      this.minDate.set(new Date(aux_date));
    }
  }

  //#region Methods:
  onChangeFrequency(target: any) {
    const frequencyId = target.value
    this.selectedFrequency = this.frequencies().find(frequency => frequency.id === frequencyId);
    if (this.selectedFrequency)
      this.onSelectedFrequencyChange.emit(this.selectedFrequency);
  }

  onDateChange(event: any) {
    const selectedDate: Date = event.value;
    const formattedDate: string = formatSubscriptionDate(selectedDate);

    this.selectedDateSubscription = formattedDate;
    if (this.selectedDateSubscription)
      this.onStartDateChange.emit(this.selectedDateSubscription);
  }

  #validateTemporaryRoute() {
    const currentSessionInfo: Session | null = this.#localStorageService.get(LOCALSTORAGE_KEYS.SESSION);

    if (!currentSessionInfo)
      return;

    const { temporaryChange, upcomingTemporaryChanges } = currentSessionInfo?.deliveryInfo ?? {};

    this.isTemporaryRouteChange.set(!!temporaryChange?.active);
    const weekTemporaryRouteChanges = [...(upcomingTemporaryChanges?.map(e => e.weekNumber) ?? []), temporaryChange?.weekNumber ?? 0];
    this.weekTemporaryRouteChanges.set(weekTemporaryRouteChanges);
    this.initiateSubscriptionImmediatly.set(!this.isTemporaryRouteChange());
  }

  onInitiateSubscriptionImmediatly(event: any) {
    this.initiateSubscriptionImmediatly.set(event.checked);
    this.onInitiateSubscriptionImmediatlyChanged.emit(event.checked);
  }
  //#endregion
}
