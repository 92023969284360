import { Component, computed, inject, input, InputSignal, output, signal, Signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalsStoreService } from '../../signals-store.service';
import { handleImageError, unableOperationMessage } from '../../common/utils';
import { ModalContentService } from '../../modal-content/modal-content.service';
import { ProductsService } from '../../../product/products.service';
import { CardTypes, ProductCard } from '../../product-card/product-card.types';
import { ProductCardV2Service } from '../product-card-v2.service';
import { Router, RouterLink } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { BundleModifyTypes } from '../../types/flows-config.types';
import { ResolutionService } from '../../resolution.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NumberRestrictionDirective } from '../../directives/number-restriction.directive';
import { OutOfStockComponent } from '../../out-of-stock/out-of-stock.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-product-card-v2-main',
  imports: [
    CommonModule,
    RouterLink,
    MatFormFieldModule,
    MatSelectModule,
    NumberRestrictionDirective,
    OutOfStockComponent,
    NgbModule,
    MatInputModule
  ],
  templateUrl: './product-card-v2-main.component.html',
  styleUrl: './product-card-v2-main.component.scss'
})
export class ProductCardV2MainComponent {

  //#region Injections
  #signalsStoreService = inject(SignalsStoreService);
  #modalContentService = inject(ModalContentService);
  #productsService = inject(ProductsService);
  #productCardV2Service = inject(ProductCardV2Service);
  #router = inject(Router)
  #resolutionService = inject(ResolutionService);
  //#endregion

  //#region Inputs
  card: InputSignal<ProductCard> = input.required();
  isOutOfStock: InputSignal<boolean> = input.required();
  isOutOfStockPerQuantity: InputSignal<boolean> = input.required();
  hasNotify: InputSignal<boolean> = input.required();
  productAttributes: InputSignal<Array<any>> = input.required();
  selectedAttribute: InputSignal<any> = input.required();
  selectedPackage: InputSignal<any> = input.required();
  selectedAttributeCardConfig: InputSignal<any> = input.required();
  wasValueHigher10: InputSignal<boolean> = input.required();
  newQuantity: InputSignal<number> = input.required();
  totalStockAvailable: InputSignal<number> = input.required();
  availableQuantities: InputSignal<Array<{ val: number, name: string | number }>> = input.required();
  stockSignal: InputSignal<Map<number | string, any>> = input.required();
  isDisabledActionButtons: InputSignal<boolean> = input.required();
  isDisabledSubscribeButton: InputSignal<boolean> = input.required();
  existsInOrder: InputSignal<{ common: boolean, subscription: boolean, totalQuantity: number }> = input.required();
  isPreOrderedVariant: InputSignal<boolean> = input.required();
  productId: InputSignal<number> = input.required();
  isFromWelcome: InputSignal<boolean> = input.required();
  //#endregion

  //#region Outputs
  outFlipCardType = output<{ type: CardTypes }>();
  outModalCheckAddress = output();
  outOnAttributeChange = output<{ id: number, event: any }>();
  outOnPackageChanged = output<any>();
  outOnInputQuantityChange = output<any>();
  outOnKeyUp = output<any>();
  outOnQuantityChange = output<any>();
  outAddProductToCart = output<{ isSubscription: boolean, event: any }>();
  outOpenModalCheckAddress = output();
  outOpenModalWhatsInside = output<any>();
  //#endregion

  //#region Signals
  updateLikeDislike: WritableSignal<boolean> = signal(false);
  imgPlaceholder = signal('assets/images/product-placeholder.webp');
  //#endregion

  //#region Computed
  hasSession: Signal<boolean> = computed(() => this.#signalsStoreService.hasSession());
  isFavorite = computed(() => this.#checkFavProduct());
  marketStatus = computed(() => this.#signalsStoreService.marketStatus());
  isMobile = computed(() => this.#resolutionService.isMobile());

  showSaleBadge = computed(() => {
    return this.selectedAttributeCardConfig().hasDiscount && this.selectedAttributeCardConfig().isOnSale
  });

  productUrl = computed(() => {
    return this.card().product?.productUrl;
  });

  productImg = computed(() => {
    const imagePlaceholder = this.imgPlaceholder();
    return this.selectedAttributeCardConfig().image || imagePlaceholder;
  });

  productRouterLink = computed(() => {
    const isFromWelcome = this.isFromWelcome();
    if (isFromWelcome) return null;
    return ['/shop/' + this.productUrl()];
  });

  showHoveredButton = computed(() => this.card().product.bundle?.items?.length);

  showMarketClosedSection = computed(() => {
    return !this.marketStatus().isOpen && !this.card().product.preOrder &&
      !this.selectedAttributeCardConfig().isSubscription
  });

  showInOrderOverlay = computed(() => {
    return (this.existsInOrder().common || this.existsInOrder().common);
  });

  productNameTooltip = computed(() => {
    return this.card().product.name;
  });

  productName = computed(() => {
    return this.card().product.name;
  });

  producerName = computed(() => {
    return this.card().product.producer?.name;
  });

  showOOSComponent = computed(() => {
    return (this.isOutOfStock() || this.isOutOfStockPerQuantity());
  });

  productDisplay = computed(() => {
    return this.selectedAttributeCardConfig().display;
  });

  productTags = computed(() => {
    return this.card().product.tags;
  });
  //#endregion

  //#region Properties:
  cardTypes = CardTypes;
  bundleModifyTypes = BundleModifyTypes;
  bundleModifyType = environment.config.flows.bundles.modifyType;
  //#endregion

  //#region Methods
  handleImageError = handleImageError;

  #checkFavProduct() {
    this.updateLikeDislike();
    let isFavorite = false;
    const selectedAttribute = this.selectedAttribute() || null;
    // If the product has attribute or has the variant info:
    if (this.card().product?.attributes?.length && !this.card().product?.variantId) {
      if (selectedAttribute) {
        for (const attr of this.card().product?.attributes || []) {
          const favValues = attr.values.filter((v: any) => v.fav);
          isFavorite = favValues.some((fv: any) => fv.id === selectedAttribute.value.id)
        }
      }
    } else
      isFavorite = this.selectedAttributeCardConfig().fav ?? !!this.card().product?.fav

    return isFavorite;
  }

  handleFavoriteChange() {
    if (this.isDisabledActionButtons()) return;
    // If there is not stock yet, we shoul prevent for this acction:
    if (!this.stockSignal()?.size) return;
    // TODO VALIDATION, IF EP STOCK FAILS, MUST SHOW A ERROR MESSAGE
    const bundle = this.card().product?.bundle || null;
    // If the product already has a variantId, must use it (it happens in buy-again or favorites)
    const variant = this.card().product?.packages?.length ?
      this.#productCardV2Service.getVariantFromPackage(this.stockSignal(), this.productId(), this.selectedAttribute()) :
      this.#productCardV2Service.getVariant(this.stockSignal(), this.productId(), this.selectedAttribute());

    if (!variant)
      return unableOperationMessage(this.#modalContentService);

    if (!bundle && !variant?.stock)
      return unableOperationMessage(this.#modalContentService);

    if (!this.isFavorite())
      this.#productsService
        .setFavorite(variant.id)
        .subscribe(() => this.#updateLikeDislikeProperty(true, true));
    else
      this.#productsService
        .removeFavorite(variant.id)
        .subscribe(() => this.#updateLikeDislikeProperty(true, false));
  }

  #updateLikeDislikeProperty(isFavorite: boolean, value: boolean) {

    const selectedAttribute = this.selectedAttribute() || null;

    if (this.card().product?.attributes?.length && !this.card().product?.variantId && selectedAttribute) {
      for (const attr of this.card().product?.attributes || []) {

        const item: any = attr.values
          .find((v: any) => v.id === selectedAttribute.value.id);

        if (!item)
          continue;

        item[isFavorite ? 'fav' : 'disliked'] = value;
        if (value)
          item[isFavorite ? 'disliked' : 'fav'] = false;
      }
    }

    this.card().product[isFavorite ? 'fav' : 'disliked'] = value;
    if (value)
      this.card().product[isFavorite ? 'disliked' : 'fav'] = false;

    this.selectedAttributeCardConfig()[isFavorite ? 'fav' : 'disliked'] = value;
    if (value)
      this.selectedAttributeCardConfig()[isFavorite ? 'disliked' : 'fav'] = false;

    this.updateLikeDislike.update(value => !value);
  }

  getButtonText(conf: { isBundle: boolean, isSubscription: boolean }) {
    const product = this.card().product;
    if (!product) return '';
    return this.#productCardV2Service.getButtonText({
      conf,
      existsInOrder: this.existsInOrder(),
      isPreorderedVariant: this.isPreOrderedVariant(),
      product
    })
  }
  //#endregion

}
