<section class="animated fadeIn d-flex flex-column flex-grow-1">
    <div class="row">
        <i class="fas fa-times text-end close-mark text-secondary-800"
            (click)="outFlipCardType.emit(cardTypes.main)"></i>
    </div>
    <h4 class="text-center mt-2 px-2 head-font text-secondary-800">
        Unfortunately, this product is out of stock.
    </h4>
    <p class="text-secondary-700">
        If you wold like us to notify you when we receive a new shipment, please enter your information below.
    </p>
    <mat-radio-group (change)="notifyMethodChanged($event)">
        <div class="row row-cols-1">
            <div class="col">
                <div class="d-flex flex-row align-items-center">
                    <mat-radio-button [checked]="true"
                        [value]="{email: session()?.accountInfo?.email}"></mat-radio-button>
                    <label class="email-container text-secondary-700" #emailContainer>
                        <div class="email-text" #emailText>
                            {{session()?.accountInfo?.email}}</div>
                    </label>
                </div>
            </div>
            <div class="col text-center text-secondary-700">
                <b>or</b>
            </div>
            <div class="col">
                <div class="d-flex flex-row align-items-center">
                    <mat-radio-button [value]="{phone: session()?.accountInfo?.phone}"></mat-radio-button>
                    <label class="text-secondary-700">
                        {{session()?.accountInfo?.phone || "" | phone}}
                    </label>
                </div>
            </div>
        </div>
    </mat-radio-group>
    <!-- Action button -->
    <div class="card-footer p-0 mt-3">
        <div class="row">
            <div class="col">
                <button type="button" (click)="outFlipCardType.emit(cardTypes.main)"
                    class="btn btn-outline-secondary d-flex gap-2 align-items-center h5-semibold head-font">
                    Cancel
                </button>
            </div>
            <div class="col">
                <button type="button" (click)="notifyMe()"
                    class="btn btn-primary d-flex gap-2 align-items-center h5-semibold head-font">
                    Notify Me!
                </button>
            </div>
        </div>
    </div>
</section>