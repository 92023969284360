import { Component, output } from '@angular/core';
import { CardTypes } from '../../product-card/product-card.types';

@Component({
  selector: 'app-product-card-v2-notified',
  imports: [],
  templateUrl: './product-card-v2-notified.component.html',
  styleUrl: './product-card-v2-notified.component.scss'
})
export class ProductCardV2NotifiedComponent {
  //#region Outputs
  outFlipCardType = output<CardTypes>();
  //#endregion

  //#region Properties:
  cardTypes = CardTypes;
  //#endregion
}
