<section class="animated fadeIn d-flex flex-column flex-grow-1">
    <!-- Card Header -->
    <div class="ps-0 pt-1 bg-transparent" [ngClass]="{'card-img active-filter-blur': isOutOfStock()}">
        <p class="m-0 text-secondary-700">
            Summary
        </p>
        <p class="h4-bold head-font text-secondary-800">
            {{ product()?.name }}
        </p>
    </div>

    @if (isOutOfStock() || isOutOfStockPerQuantity()) {
    <app-out-of-stock [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [isOutOfStock]="isOutOfStock()"
        [hasNotify]="hasNotify()" [isFromSummary]="true" (outFlipCardType)="outFlipCardType.emit($event)"
        (outModalCheckAddress)="outModalCheckAddress.emit()"></app-out-of-stock>
    }
    <section [class.d-none]="isOutOfStock()">
        <div class="card-body p-0 mt-2">
            <div class="row">
                <!-- Product Customizations  -->
                @if (isGiftCard()) {
                <div class="col-lg-6 col-md-12">
                    <p class="m-0 h7-semibold">Amount:</p>
                </div>
                }
                <div [class.col-md-12]="isGiftCard()" [class.col-lg-6]="isGiftCard()" [class.col-12]="!isGiftCard()">
                    @if (productAttributes().length) {
                    @for (attr of productAttributes(); track $index) {
                    <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                        <mat-label class="text-capitalize">{{attr.name.toLowerCase()}}</mat-label>
                        <mat-select [value]="selectedAttribute()?.value?.id || ''"
                            (selectionChange)="outOnAttributeChange.emit({id:attr.id, event:$event})">
                            @for (item of attr.values; track $index) {
                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    }
                    }
                    @if (product()?.packages?.length) {
                    <mat-form-field class="rounded-selector" [class.mt-2]="productAttributes().length"
                        subscriptSizing="dynamic">
                        <mat-label class="text-capitalize">Package</mat-label>
                        <mat-select [value]="selectedPackage()?.id || ''"
                            (selectionChange)="outOnPackageChanged.emit($event.value)">
                            @for (item of product()?.packages; track $index) {
                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    }
                </div>
            </div>

            @if (!isGiftCard()) {
            <!-- Quantity buttons -->
            <div class="row row-cols-2 d-flex justify-content-end align-items-center mt-2">
                <div class="col-7">
                    <div class="card-price d-flex gap-1 flex-row align-items-center">
                        @if(!selectedAttributeCardConfig().isABundle &&
                        !selectedAttributeCardConfig().bundle?.items?.length &&
                        selectedAttributeCardConfig().bundle?.priceRange) {
                        <h5 class="card-title mb-0 font-variant-nums">{{
                            selectedAttributeCardConfig().bundle?.priceRange}}</h5>
                        } @else {
                        <h5 class="card-title mb-0 font-variant-nums">{{ (selectedAttributeCardConfig().price) |
                            currency}}</h5>
                        }
                        @if(selectedAttributeCardConfig().hasDiscount) {
                        <small class="text-body-tertiary fw-bold text-decoration-line-through font-variant-nums">
                            {{selectedAttributeCardConfig().originalPrice | currency}}
                        </small>
                        }
                    </div>
                </div>
                <div class="col-5 quantity">
                    @if (wasValueHigher10()) {
                    <small class="how-many-label d-none d-sm-block">How many?</small>
                    }
                    <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                        <mat-label>Quantity</mat-label>
                        @if( wasValueHigher10() || newQuantity() >= 10 || (newQuantity() >= 10 &&
                        wasValueHigher10()) ){
                        <input type="text" matInput appNumberRestriction [minNumber]="1"
                            [maxNumber]="totalStockAvailable()" (change)="outOnInputQuantityChange.emit($event)"
                            (keyup)="outOnKeyUp.emit($event)" [value]="newQuantity()">
                        }
                        @else{
                        <mat-select [value]="newQuantity()" (selectionChange)="outOnQuantityChange.emit($event)">
                            @for(q of availableQuantities(); track $index){
                            <mat-option [value]="q.val">{{ q.name }}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                </div>
            </div>
            }

            <hr class="border-1 opacity-100 border-disable">

            <!-- Total calculates -->
            @if(!(!selectedAttributeCardConfig().isABundle &&
            !selectedAttributeCardConfig().bundle?.items?.length &&
            selectedAttributeCardConfig().bundle?.priceRange)) {
            <div class="row row-cols-2 mt-3 d-flex justify-content-between align-items-center">
                <div class="quantity">
                    <h5 class="h3-semibold text-secondary-700">Total</h5>
                </div>
                <div class="card-price text-right">
                    <h5 class="h3-semibold font-variant-nums text-secondary-800">
                        {{ summaryTotalPrice() | currency }}
                    </h5>
                </div>
            </div>
            }
        </div>
    </section>
    <!-- Action button -->

    @if (!isGiftCard()) {
    @if (!(!isOutOfStockPerQuantity() && hasNotify())) {
    @if(!isOutOfStock()) {
    <div class="product-card__action">
        <div class="d-flex">
            @if (hasSession()) {
            @if ((selectedAttributeCardConfig().isSubscription)) {
            @if (product()?.bundle?.items?.length) {
            <div class="d-flex gap-2 w-100">
                @if(stockSignal().size) {
                @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
                <button type="button" (click)="outAddProductToCart.emit({isSubscription: false,event: $event})"
                    [disabled]="isDisabledActionButtons()"
                    class="btn btn-outline-secondary d-flex gap-2 align-items-center head-font">
                    @if (isMobile()) {
                    <i class="fas fa-plus"></i>
                    } @else {
                    {{getButtonText({isBundle: true, isSubscription: false})}}
                    }
                </button>
                }
                <button type="button" [disabled]="isDisabledSubscribeButton()"
                    (click)="outFlipCardType.emit(cardTypes.subscribe)"
                    class="btn btn-secondary d-flex gap-2 align-items-center head-font">
                    {{getButtonText({isBundle: true, isSubscription: true})}}
                </button>
                } @else {
                <p class="placeholder-glow mb-0 w-100">
                    <span
                        class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                        style="height: 36px;"></span>
                </p>
                }
            </div>
            } @else {
            <div class="d-flex gap-2 w-100">
                @if(stockSignal().size) {
                @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
                <button type="button" (click)="outAddProductToCart.emit({isSubscription: false, event: $event})"
                    [disabled]="isDisabledActionButtons()"
                    class="btn btn-outline-secondary d-flex gap-2 align-items-center head-font">
                    @if (isMobile()) {
                    <i class="fas fa-plus"></i>
                    } @else {
                    {{getButtonText({isBundle: false, isSubscription: false})}}
                    }
                </button>
                }
                <button type="button" [disabled]="isDisabledSubscribeButton()"
                    (click)="outFlipCardType.emit(cardTypes.subscribe)"
                    class="btn btn-secondary d-flex gap-2 align-items-center head-font">
                    {{getButtonText({isBundle: false, isSubscription: true})}}
                </button>
                } @else {
                <p class="placeholder-glow mb-0 w-100">
                    <span
                        class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                        style="height: 36px;"></span>
                </p>
                }
            </div>
            }
            } @else {
            @if(stockSignal().size && selectedAttributeCardConfig().isALaCarte) {
            <button type="button" (click)="outAddProductToCart.emit({isSubscription: false, event: $event})"
                [disabled]="isDisabledActionButtons() || (!product()?.preOrder && !marketStatus().isOpen)"
                class="btn btn-secondary d-flex gap-2 align-items-center head-font"
                [class.btn-outline-secondary]="existsInOrder().common || isPreOrderedVariant()">
                {{getButtonText({isBundle: false, isSubscription: false})}}
            </button>
            } @else {
            <p class="placeholder-glow mb-0 w-100">
                <span
                    class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                    style="height: 36px;"></span>
            </p>
            }
            }
            } @else {
            <button type="button" (click)="outOpenModalCheckAddress.emit()"
                class="btn btn-secondary d-flex gap-2 align-items-center head-font">
                Add To Delivery
            </button>
            }

        </div>
    </div>
    }
    }
    }
</section>