<section class="d-flex flex-column flex-grow-1">

    <div class="product-card__img-wrap">

        <a>
            <div class="product-card__img"></div>
        </a>

    </div>

    <!-- Card Header - Title and Subtitle -->
    <div class="product-card__head">
        <a>
            <h3 class="product-card__title">
                {{product().name}}
            </h3>
        </a>
        <h5 class="product-card__subtitle">
            {{product().producer?.name}}
        </h5>
    </div>

    <section>
        <div class="product-card__meta">
            <div class="product-card__meta--item is-col-2">
                <div class="d-grid">
                    <div class="d-flex gap-1 d-flex gap-1 flex-row align-items-center">
                        <h5 class="product-card__price">
                            <span class="material-symbols-outlined rotate-icon">
                                progress_activity
                            </span>
                        </h5>
                    </div>
                </div>

                <!-- Quantity buttons -->
                <div class="col quantity">
                    <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                        <mat-select value="1">
                            <mat-option value="1">1</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

            <div class="row">
                <!-- Product Customizations  -->
                <div class="col-12 d-flex gap-1">
                    @if (product().attributes?.length) {
                    @for (attr of product().attributes; track $index) {
                    @if (attr.values.length) {
                    <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                        <mat-label class="text-capitalize">{{attr.name.toLowerCase()}}</mat-label>
                        @if (attr.values.length === 1) {
                        <input matInput type="text" readonly="true" [value]="attr.values[0].name">
                        } @else {
                        <mat-select [value]="attr.values[0].id || ''">
                            @for (item of attr.values; track $index) {
                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                    }
                    }
                    }
                    @if (product().packages?.length) {
                    <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                        <mat-label class="text-capitalize">Package</mat-label>
                        @if (product().packages?.length === 1) {
                        <input matInput type="text" readonly="true" [value]="product().packages?.[0].name || ''">
                        } @else {
                        <mat-select [value]="product().packages?.[0].id || ''">
                            @for (item of product().packages; track $index) {
                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                            }
                        </mat-select>
                        }
                    </mat-form-field>
                    }
                </div>
            </div>

            <p class="product-card__info">
                {{product().display || ''}}
            </p>

        </div>

        <!-- Action button -->
        <div class="product-card__action">
            <div class="d-flex gap-2 w-100">
                @if(hasSession()) {
                @if (product().isSubscription) {
                <div class="d-flex gap-2 w-100">
                    @if (marketStatus().isOpen && product().isALaCarte) {
                    <button type="button" [disabled]="true"
                        class="btn btn-outline-secondary d-flex gap-2 align-items-center head-font">
                        @if (isMobile()) {
                        <i class="fas fa-plus"></i>
                        } @else {
                        Add to Cart
                        }
                    </button>
                    }
                    <button type="button" [disabled]="true"
                        class="btn btn-secondary d-flex gap-2 align-items-center head-font">
                        Subscribe
                    </button>
                </div>
                }
                @else {
                @if(product().isALaCarte) {
                <button type="button" [disabled]="true"
                    class="btn btn-secondary d-flex gap-2 align-items-center head-font">
                    Add to Cart
                </button>
                }
                }
                } @else {
                <button type="button" class="btn btn-secondary d-flex gap-2 align-items-center head-font">
                    Add To Delivery
                </button>
                }
            </div>
        </div>
    </section>


    <!-- Tags Icons -->
    <section class="product-card__tags">
        @for (tag of product().tags || []; track $index) {
        <div>
            {{tag.name}}
        </div>
        }
    </section>

</section>