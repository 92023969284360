<section class="animated fadeIn d-flex flex-column flex-grow-1">
    <div class="row">
        <i class="fas fa-times text-end close-mark text-secondary-800"
            (click)="outFlipCardType.emit(cardTypes.main)"></i>
    </div>
    <div class="d-flex h-100 justify-content-center align-items-center px-1 flex-column text-center">
        <h4 class="head-font text-secondary-800">
            You'll be notified!
        </h4>
        <p class="text-secondary-700">
            Thank you for your interest in this product. We will let you know when it's back in stock.
        </p>
    </div>
</section>