import { AfterViewInit, Component, computed, ElementRef, HostListener, Inject, inject, input, InputSignal, output, PLATFORM_ID, signal, Signal, ViewChild, WritableSignal } from '@angular/core';
import { CardTypes } from '../../product-card/product-card.types';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { AccountService } from '../../account.service';
import { tap } from 'rxjs';
import { SignalsStoreService } from '../../signals-store.service';
import { Session } from '../../types/session.type';
import { PhonePipe } from '../../pipes/phone.pipe';

@Component({
  selector: 'app-product-card-v2-notify',
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatRadioModule,
    FormsModule,
    PhonePipe
  ],
  templateUrl: './product-card-v2-notify.component.html',
  styleUrl: './product-card-v2-notify.component.scss'
})
export class ProductCardV2NotifyComponent implements AfterViewInit {

  //#region ViewChilds
  /*fontsize adjust for email so its doesn't break or overflow*/
  @ViewChild('emailContainer') emailContainer!: ElementRef;
  @ViewChild('emailText') emailText!: ElementRef;
  //#endregion

  //#region Injections:
  #accountService = inject(AccountService);
  #signalsStoreService = inject(SignalsStoreService);
  //#endregion

  //#region Inputs:
  productId: InputSignal<number> = input.required();
  //#endregion

  //#region Outputs
  outFlipCardType = output<CardTypes>();
  //#endregion

  //#region Computed:
  session: Signal<Session | null> = computed(() => this.#signalsStoreService.sessionSignal());
  //#endregion

  //#region Signals:
  notifySelectedOption: WritableSignal<{ email?: string, phone?: string } | null> = signal(this.session()?.accountInfo?.email ? { email: this.session()?.accountInfo?.email } : null);
  //#endregion

  //#region Properties:
  cardTypes = CardTypes;
  //#endregion

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngAfterViewInit(): void {
    this.adjustFontSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.adjustFontSize();
  }

  //#region Methods:
  notifyMe() {
    if (!this.notifySelectedOption()) return;
    this.#accountService.notifyOOS(this.notifySelectedOption(), this.productId()).pipe(
      tap(() => this.outFlipCardType.emit(CardTypes.notified))
    ).subscribe();
  }

  adjustFontSize(): void {
    if (!isPlatformBrowser(this.platformId) || !this.emailContainer?.nativeElement || !this.emailText?.nativeElement) return;
    const containerWidth = this.emailContainer.nativeElement.offsetWidth; // Responsive width
    const emailElement = this.emailText.nativeElement;
    let fontSize = 16; // Initial font size
    emailElement.style.fontSize = `${fontSize}px`;

    // Reduce font size until the text fits within the container
    while (emailElement.scrollWidth > containerWidth && fontSize > 12) { // Set a minimum font size of 8px
      fontSize--;
      emailElement.style.fontSize = `${fontSize}px`;
    }
  }

  notifyMethodChanged(event: any) {
    this.notifySelectedOption.set(event.value);
  }
  //#endregion
}
