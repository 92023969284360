import { WritableSignal } from "@angular/core";
import { Product, ProductResume } from "../../product/product.types";
import { Item } from "../types/common.types";

export interface ProductCardSettings {
  hasStock: boolean;
  showFlavors: boolean;
  showSizes: boolean;
  showContentInfo: boolean;
  showAttributes: boolean;
  isSummary: boolean;
  isCardInOrderPage?: boolean;
  isPlaceHolder: boolean;
}

export interface ProductCardResume {
  orderId?: number;
  product?: ProductResume;
  settings: Partial<ProductCardSettings>;
  productDetails?: Product;
}

export interface ProductCard {
  orderId?: number;
  product: Product;
  settings: Partial<ProductCardSettings>;
}

export interface Category extends Item {
  minimumSpend: number;
}

export enum CardTypes {
  main = 'main',
  checkAddress = 'checkAddress',
  notify = 'notify',
  notified = 'notified',
  subscribe = 'subscribe',
  summary = 'summary',
  resume = 'resume',
  none = 'none'
}