//#region IMPORTS:
import { CommonModule, Location } from '@angular/common';
import { Component, computed, inject, input, InputSignal, output, signal, Signal, WritableSignal, AfterViewInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { tap, combineLatest, delay } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BundleItems, PreOrderedProduct, Product, ProductAttributeValues } from '../../product/product.types';
import { SubscriptionsService } from '../../settings/account/subscriptions/subscriptions.service';
import { Subscription, SubscriptionPayload } from '../../settings/account/subscriptions/subscriptions.types';
import { StockService } from '../../stock/stock.service';
import { arrayToMapMultiKey, sanitizeNumericInput, unableOperationMessage, isANoDeliveryDayUser, isANoPaymentMethodUser, isAddressUpdateRequired, isNoAddressUser, isSuspendedUser, arrayToMap } from '../common/utils';
import { LOCALSTORAGE_KEYS } from '../constants/databases';
import { ModalContentTypes } from '../constants/modal-content-types';
import { LocalStorageService } from '../local-storage.service';
import { ModalContentData } from '../modal-content/modal-content';
import { ModalContentService } from '../modal-content/modal-content.service';
import { NotificationService } from '../notification/notification.service';
import { OrderService } from '../order.service';
import { CardTypes, ProductCard, ProductCardResume } from '../product-card/product-card.types';
import { SignalsStoreService } from '../signals-store.service';
import { Item } from '../types/common.types';
import { BundleModifyTypes } from '../types/flows-config.types';
import { BundleEditionTypes, FireBaseProductOrder } from '../types/order.type';
import { ProductCardV2SummaryComponent } from './product-card-v2-summary/product-card-v2-summary.component';
import { ProductCardV2MainComponent } from './product-card-v2-main/product-card-v2-main.component';
import { ProductCardV2NotifyComponent } from './product-card-v2-notify/product-card-v2-notify.component';
import { ProductCardV2NotifiedComponent } from './product-card-v2-notified/product-card-v2-notified.component';
import { ProductCardV2SubscribeComponent } from './product-card-v2-subscribe/product-card-v2-subscribe.component';
import { ProductCardV2Service } from './product-card-v2.service';
import { ProductCardV2ResumeComponent } from "./product-card-v2-resume/product-card-v2-resume.component";
import { formatDateToReadableString } from '../utils/formatting';
//#endregion

@Component({
  selector: 'app-product-card-v2',
  imports: [
    CommonModule,
    RouterModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    NgbModule,
    ProductCardV2SummaryComponent,
    ProductCardV2MainComponent,
    ProductCardV2NotifyComponent,
    ProductCardV2NotifiedComponent,
    ProductCardV2SubscribeComponent,
    ProductCardV2ResumeComponent
  ],
  providers: [{
    provide: Loader,
    useValue: new Loader({
      apiKey: environment.apis.google.places,
      libraries: ['places'],
      region: 'US',
    }),
  }, SubscriptionsService],
  templateUrl: './product-card-v2.component.html',
  styleUrl: './product-card-v2.component.scss'
})
export class ProductCardV2Component implements AfterViewInit {

  //#region Injections
  #location = inject(Location)
  #notificationService = inject(NotificationService);
  #localStorageService = inject(LocalStorageService);
  #stockService = inject(StockService);
  #signalsStoreService = inject(SignalsStoreService);
  #modalService = inject(ModalContentService);
  #router = inject(Router);
  #orderService = inject(OrderService);
  #subscriptionsService = inject(SubscriptionsService);
  #activeModal = inject(NgbModal);
  #productCardV2Service = inject(ProductCardV2Service);
  //#endregion

  //#region Inputs
  cardResume: InputSignal<ProductCardResume> = input.required();
  isFromWelcome = input<boolean>(false);
  isFromBlog = input(false);
  isGiftCard: InputSignal<boolean> = input(false);

  isFromShop: InputSignal<boolean | undefined> = input();
  #isFromShop$ = toObservable(this.isFromShop);
  //#endregion

  //#region Outputs
  onComponentReady = output();
  outSelectedVariant = output<any>({ alias: 'selectedVariant' });
  outShowDiscountTag = output<boolean>();
  //#endregion

  //#region Normal properties
  allVariantsOutOfStock = false;
  bundleModifyTypes = BundleModifyTypes;
  bundleModifyType = environment.config.flows.bundles.modifyType;
  cardType = signal(CardTypes.resume);
  cardTypes = CardTypes;
  defaultCard: Partial<ProductCardResume> | any = {
    product: undefined,
    settings: {
      hasStock: false,
      showAttributes: true
    }
  }
  hasNotify = false;
  productAttributes: any[] = [];
  wasValueHigher10!: boolean;

  #variant: any = null;
  #bundle: any = null;
  //#endregion

  //#region Computed Values
  availableQuantities = computed(() => this.#setUpAvailableQuantities());
  existsInOrder: Signal<{ common: boolean, subscription: boolean, totalQuantity: number }> = computed(() => this.#existsInOrder());
  firebaseOrder = computed(() => this.#signalsStoreService.firebaseOrder() || null);
  isDisabledActionButtons = computed(() => this.shouldDisableActionButtons());
  isDisabledSubscribeButton = computed(() => this.shouldDisableSubscribeActionButtons());
  isOutOfStockPerQuantity = computed(() => this.stockValidation() && this.hasStockValidationPerQuantity());
  isPreOrderedVariant = computed(() => this.#setUpIsPreOrderedVariant());
  marketStatus = computed(() => this.#signalsStoreService.marketStatus());
  odooOrder = computed(() => this.#orderService.odooOrder() || null);
  selectedAttributeCardConfig = computed(() => this.setUpSelectAttributeConfig())
  stockValidation = computed(() => this.#isBundleOutOfStock() || this.setUpStockForProductVariant());
  storedSubscriptions = computed(() => this.#signalsStoreService.subscriptions() || []);
  totalStockAvailable = computed(() => this.#setUpTotalStockAvailable());
  //#endregion

  //#region Signals
  card: WritableSignal<ProductCard> = signal(this.defaultCard);
  hasStockValidationPerQuantity: WritableSignal<boolean> = signal(false);
  isOutOfStock: WritableSignal<boolean> = signal(false);
  initiateSubscriptionImmediatly: WritableSignal<boolean> = signal(true);
  mapBundleStockSignal: WritableSignal<Map<number | string, any>> = signal(new Map());
  newQuantity: WritableSignal<any> = signal(1);
  preOrders: WritableSignal<PreOrderedProduct[]> = signal([]);
  product: WritableSignal<Product | undefined> = signal(undefined);
  productId: WritableSignal<number> = signal(0);
  selectedAttribute: WritableSignal<any> = signal('')
  selectedPackage: WritableSignal<any> = signal('')
  selectedQuantity: WritableSignal<any> = signal(1);
  stock: WritableSignal<Map<string | number, any>> = signal(new Map());
  selectedFrequency: WritableSignal<Item | undefined> = signal(undefined);
  selectedDateSubscription: WritableSignal<string | undefined> = signal(undefined);

  #justAddedProduct = this.#signalsStoreService.justAddedProduct;
  //#endregion

  //#region Observables
  #stockValidation$ = toObservable(this.stockValidation);
  #hasStockValidationPerQuantity$ = toObservable(this.hasStockValidationPerQuantity);
  //#endregion

  ngAfterViewInit(): void {
    this.onComponentReady.emit();

    if (this.isFromShop() === undefined)
      this.#cardResumeTransform();

    this.#isFromShop$.subscribe(
      (value: boolean | undefined) => {
        if (value === true) {
          this.#cardResumeTransform();
        }
      }
    )
  }

  //#region Methods
  onKeyUp(event: any): void {
    const { value } = event?.target

    if (+ value >= 10)
      this.wasValueHigher10 = true;

    this.newQuantity.set(+value)
    this.hasStockValidationPerQuantity.set(true);
  }

  onInputQuantityChange(event: any) {
    const { value } = event?.target
    this.wasValueHigher10 = +value >= 10;
  }

  onQuantityChange(target: any) {
    if (+target?.value >= 10)
      this.wasValueHigher10 = true;

    this.newQuantity.set(+target?.value);
    this.hasStockValidationPerQuantity.set(true);
  }

  onInputChange(event: any) {
    const newValue = +sanitizeNumericInput(event);
    this.selectedQuantity.set(newValue);
    event.target.value = newValue;
  }

  onAttributeChange(attrId: number, target: any) {
    const valueId = target.value
    let attributeSelected: any;
    for (const attribute of this.product()?.attributes as any) {
      if (!attributeSelected && attribute.id === attrId) {
        const value = attribute.values?.find((value: any) => value.id === valueId);
        if (value) value.hasDiscount = !!value.originalPrice;
        attributeSelected = {
          id: attribute.id,
          name: attribute.name,
          value
        }
        break;
      }
    }

    this.selectedAttribute.set(attributeSelected);
  }

  onPackageChanged(value: string | number) {
    let packageSelected: any;
    packageSelected = this.product()?.packages?.find((pkg: any) => pkg.id === value)
    this.selectedPackage.set(packageSelected);
  }

  async addProductToCart(isSubscription: boolean = false, event: any) {
    // If the user has not delivery day:
    if (isANoDeliveryDayUser(this.#localStorageService, this.#modalService)) return;
    // If the user has not payment method (abandoned user):
    if (isANoPaymentMethodUser(this.#localStorageService, this.#modalService, this.#activeModal)) return;
    // If the should update their address:
    if (isAddressUpdateRequired(this.#localStorageService, this.#modalService, this.#activeModal, this.#router)) return;
    // If the user does not has address:
    if (isNoAddressUser(this.#localStorageService, this.#modalService, this.#activeModal, this.#router)) return;

    // If the product is available for Pre-Order:
    if (this.product()?.preOrder) {
      this.#openModalPreOrderProduct();
      return;
    };
    // If the user has their order marked as skipped, prevent the full process:
    const hasSkippedOrder = await this.hasSkippedOrder();
    if (hasSkippedOrder) return;

    // If the product is a bundle and the environment configuration for bundles modify type is outOfOrder, then need to redirect to custom-box component:
    if (this.isCustomBundleModificationType(isSubscription)) return;

    // If it's a subscription it's not necessary to validate and get variant and bundle
    // because it was already validated and configured at flipCardType method:
    if (!isSubscription && !this.validateProductAndSetUpVariant()) return;

    /*
    If the user did not want to start the subscription immediatly, we should to save the subscription directly.
    This subscription shouldn't be added to the  cart or the order:
    */
    if (this.selectedFrequency() && !this.initiateSubscriptionImmediatly()) {
      console.log(this.initiateSubscriptionImmediatly());
      // Validate if the user doesn't has this product as subscription pending to submit in the firebase order
      if (this.existsProductAsPendingSubscription()) return;
      const subscription = this.setUpSubscriptionPayload(this.#variant);
      if (!subscription) return this.#notificationService.show({ text: 'You should select a valid frequency and start date for the subscription.', type: 'error' });
      this.#subscriptionsService.create(subscription).pipe(
        tap(() => {
          this.showMessageAsModal(
            'Subscription created successfully',
            'Your subscription has been saved! You can manage it anytime in the <a class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover cursor-pointer" routerLink="/settings/account/subscriptions">Manage Subscriptions<a> section.'
          );

          if (!this.isFromWelcome())
            this.#signalsStoreService.showCartPreview.set(true);
        })
      ).subscribe();
    } else {
      const showSuccessMessage = !!this.cardResume().orderId;
      const productToAdd = this.setUpProductToAdd(isSubscription);
      this.#orderService.addProductToFirebaseOrder(productToAdd, showSuccessMessage);
      this.#justAddedProduct.set(productToAdd);
      if (!this.isFromWelcome())
        this.#signalsStoreService.showCartPreview.set(true);
      event.stopPropagation();
    }

    this.flipCardType(this.cardResume().settings?.isSummary || false ? CardTypes.summary : CardTypes.main);
    this.selectedFrequency.set(undefined);

    if (this.isFromWelcome()) {
      this.#localStorageService.set(LOCALSTORAGE_KEYS.NEW_ACCOUNT, true);
      this.#location.replaceState('/shop');
      window.location.reload();
    }
  }

  async flipCardType(cardType: CardTypes) {
    switch (cardType) {
      case CardTypes.subscribe:
        // If the user has their order marked as skipped, prevent the full process:
        const hasSkippedOrder = await this.hasSkippedOrder(true);
        if (hasSkippedOrder) return;

        this.validateProductAndSetUpVariant();
        if (this.existsProductAsSubscription()) cardType = CardTypes.main;

        // If the product is a bundle and the environment configuration for bundles modify type is outOfOrder, then need to redirect to custom-box component:
        if (this.isCustomBundleModificationType(true)) return;

        break;
      case CardTypes.main:
        this.selectedFrequency.set(undefined);
        break;
      default:
        break;
    }
    if (!this.isFromWelcome()) {
      this.cardType.set(cardType);
    }
  }

  #shouldDisabledActionButtonsBase(): boolean {
    return !this.stock().size ||
      (this.product()?.attributes?.length && !this.selectedAttribute()) ||
      (this.product()?.packages?.length && !this.selectedPackage()) ||
      (this.isOutOfStock() || this.isOutOfStockPerQuantity()) ||
      (this.isPreOrderedVariant())
  }

  private shouldDisableActionButtons(): boolean {
    return !!this.#shouldDisabledActionButtonsBase() || !!(
      this.existsInOrder().common &&
      this.product()?.bundle?.isLocked
    )
  }

  private shouldDisableSubscribeActionButtons(): boolean {
    return !!this.#shouldDisabledActionButtonsBase() ||
      !!(this.existsInOrder().subscription && this.bundleModifyType === this.bundleModifyTypes.default) ||
      !!(this.existsInOrder().subscription && this.product()?.bundle?.isLocked)
  }

  private isCustomBundleModificationType(isSubscription: boolean) {
    if (this.product()?.bundle?.items?.length && environment.config.flows.bundles.modifyType === BundleModifyTypes.outOfOrder && !this.product()?.bundle?.isLocked) {
      if ((isSubscription && this.existsInOrder().subscription) || (!isSubscription && this.existsInOrder().common)) {

        if (!this.isFromBlog())
          this.#router.navigate([`/shop/custom-box/${isSubscription ? BundleEditionTypes.subscription : BundleEditionTypes.aLaCarte}/${this.product()?.id}`]);

        return true;
      }
    }
    return false;
  }

  #openModalPreOrderProduct() {
    this.#modalService.openModal(
      ModalContentTypes.PREORDER_PRODUCT,
      {
        title: `Confirm Your Pre-Order`,
        preOrderProduct: {
          ...this.product(),
          _variantData: this.selectedAttributeCardConfig()
        },
      }
    ).closed.subscribe(
      (res: { sendPreOrder: boolean }) => {
        if (res?.sendPreOrder) {
          this.#savePreorderProduct()
        }
      }
    )
  }

  validateProductAndSetUpVariant(): boolean {
    // If the user is suspended, we should prevent for this action:
    if (isSuspendedUser(this.#modalService, this.#localStorageService)) return false;
    // If there is not stock yet, we should prevent for this action:
    if (!this.stock().size) return false;
    // TODO VALIDATION, IF EP STOCK FAILS, MUST SHOW A ERROR MESSAGE
    this.#bundle = this.product()?.bundle || null;
    // If the product already has a variantId, must use it (it happens in buy-again or favorites)
    const hasPackages: boolean = !!this.product()?.packages?.length;
    this.#variant = hasPackages ?
      this.#productCardV2Service.getVariantFromPackage(this.stock(), this.productId(), this.selectedAttribute()) :
      this.#productCardV2Service.getVariant(this.stock(), this.productId(), this.selectedAttribute());
    if (!this.#variant) {
      unableOperationMessage(this.#modalService);

      return false
    }

    if (!this.#bundle && !this.#variant?.stock) {
      unableOperationMessage(this.#modalService);
      return false
    }

    return true;
  }

  private setUpSubscriptionPayload(variant: any): SubscriptionPayload | null {
    const frequencyId = this.selectedFrequency()?.id || null;
    const startDate = this.selectedDateSubscription() || null;
    if (!frequencyId || !startDate) return null
    return {
      frequencyId,
      packageId: this.selectedPackage()?.id || null,
      startDate,
      quantity: this.newQuantity(),
      variantId: variant.id
    };
  }

  private setUpProductToAdd(isSubscription: boolean) {
    const selectedFrequency = this.selectedFrequency() || null;
    const selectedDateSubscription = this.selectedDateSubscription() || null;
    return {
      updatedAt: DateTime.now().toMillis(),
      bundle: this.#bundle,
      category: this.product()?.category,
      id: this.product()?.id,
      img: this.product()?.img,
      isASubscription: isSubscription,
      isFromCard: true,
      name: this.product()?.name,
      package: this.selectedPackage() || null,
      presentation: this.product()?.presentation || null,
      price: this.selectedAttributeCardConfig().price,
      originalPrice: this.selectedAttributeCardConfig().originalPrice ?? this.selectedAttributeCardConfig().price,
      productUrl: this.product()?.productUrl,
      quantity: this.newQuantity(),
      subCategory: this.product()?.subcategory as any,
      subscription: !selectedFrequency ? null : {
        startDate: selectedDateSubscription || null,
        frequency: selectedFrequency
      },
      taxes: this.product()?.taxes,
      totalPrice: this.newQuantity() * (this.product()?.price as number),
      variant: this.#variant,
      isPublishedBundle: this.product()?.isPublishedBundle ?? false
    } as Partial<FireBaseProductOrder>
  }

  private existsProductAsPendingSubscription() {
    const products = this.firebaseOrder()?.products?.subscription || [];
    if (!products?.length) return false;

    // If the product is a bundle and the editio type is out of order:
    if (this.product()?.bundle?.items?.length && this.bundleModifyType === BundleModifyTypes.outOfOrder) return false;

    // The product exists in firebase order as subscription with pendind changes
    const existsInOrder = products.some((product: any) => product.variant.id === this.#variant.id);
    if (existsInOrder)
      this.showMessageAsModal('Already subscribed', 'You already have an active subscription to this product. Please delete the existing subscription to add a new one with different characteristics');
    return existsInOrder;
  }

  private existsProductAsSubscription() {
    const products = this.getAllProducts();
    if (!products?.odooOrderSubscriptions?.length || !products?.storedSubscriptions?.length) return false;

    // If the product is a bundle and the editio type is out of order:
    if (this.product()?.bundle?.items?.length && this.bundleModifyType === BundleModifyTypes.outOfOrder) return false;

    // The product exists in order
    const existsInOrder = products.odooOrderSubscriptions.some((product: any) => product.variant.id === this.#variant.id);
    if (existsInOrder) {
      const message = this.setUpProductExistsMessage(true);
      this.showMessageAsModal('Already subscribed', message);
      return true;
    }

    // The product exists in user subscriptions:
    const existsAsSubscription = products.storedSubscriptions.some((subscription: Subscription) => subscription.product.variantId === this.#variant.id);
    if (existsAsSubscription) {
      const message = this.setUpProductExistsMessage(false);
      this.showMessageAsModal('Already subscribed', message);
    }

    return existsAsSubscription;
  }

  private setUpProductExistsMessage(isInOrder: boolean): string {
    const productSource = isInOrder ? 'this product in your order' : 'an active subscription to this product';
    const gotoMessage = isInOrder ? 'checkout' : 'manage subscriptions';
    const routerLink = isInOrder ? '/order' : '/settings/account/subscriptions';
    const aOpenTag = `<a class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover cursor-pointer" routerLink="${routerLink}">`;

    return `You already have ${productSource}. Please ${aOpenTag}go to ${gotoMessage}</a> to edit your subscription`;
  }

  //#region Preorder Product
  #savePreorderProduct() {
    let preOrderData = null;

    if (!this.validateProductAndSetUpVariant()) return;

    try {
      const product = this.product();
      if (!product) return;
      preOrderData = this.#productCardV2Service.setUpProductForPreOrder({
        product,
        variantId: this.#variant.id,
        packageId: this.selectedPackage()?.id ?? undefined,
        quantity: this.newQuantity()
      });
    } catch (error) {
      return unableOperationMessage(this.#modalService);
    }

    if (!preOrderData) return unableOperationMessage(this.#modalService);

    this.#orderService.savePreOrderProduct(preOrderData).pipe(
      tap(() => this.#afterProductPreordered())
    ).subscribe();

    return false;
  }

  #afterProductPreordered() {
    this.#orderService.getPreOrders().pipe(tap(res => this.preOrders.set(res))).subscribe();

    this.showMessageAsModal(
      'Pre-Order Saved Successfully',
      'Your pre-order has been saved! You can review your pre-orders anytime in the <a class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover cursor-pointer" routerLink="/settings/account/subscriptions">Manage Subscriptions<a> section.'
    );
  }

  //#endregion

  private getAllProducts() {
    return { odooOrderSubscriptions: this.odooOrder()?.products?.subscription ?? [], storedSubscriptions: this.storedSubscriptions() ?? [] }
  }

  private showMessageAsModal(title: string, textContent: string, buttonText: string = 'I understand') {
    this.#modalService.openModal(
      ModalContentTypes.CONFIRMATION,
      {
        title,
        textContent,
        confirmButtonText: 'I understand'
      }
    )
  }

  private setUpStockForProductVariant() {
    // This is for PFW to display unavailable favorite or buy again products as out of stock:
    //#region Unavaible as OOS
    const product: any = this.product();
    if (!product) return false;
    const propertiesToCheck = ['visibleOnEcommerce', 'visibleToSale'];
    if (propertiesToCheck.some(prop => product.hasOwnProperty(prop) && product[prop] === false)) {
      this.hasNotify = product.isAbleToNotify ?? true;
      return true;
    }
    //#endregion
    if (!(product?.checkStock) || product?.bundle?.items?.length) return false;

    if (this.allVariantsOutOfStock) return true;
    const stockMap = this.stock();
    let quantity = this.newQuantity();
    const key = this.#productCardV2Service.getKeyForStockMap(this.productId());
    const pack = this.selectedPackage();
    if (pack?.quantity) quantity = quantity * pack?.quantity;
    const stock = stockMap.get(key);
    this.hasNotify = false;
    if (!stock) return false;

    if (stock.stock <= 0) {
      let oosAfterQuantityChange = (stock.stock - quantity) < stock.limit;
      if (stock.limit === 0 || oosAfterQuantityChange) {
        this.hasNotify = stock.hasNotify;
        return true
      };
    } else {
      const newStock = stock.stock - quantity;
      if (newStock < 0) {
        let oosAfterQuantityChange = newStock < stock.limit;
        if (stock.limit === 0 || oosAfterQuantityChange) {
          this.hasNotify = stock.hasNotify;
          return true;
        }
      }
    }

    return false;
  }

  private setUpSelectAttributeConfig() {
    const attr = this.selectedAttribute();
    const pack = this.selectedPackage();
    const product = this.product();
    if (!product) return;
    const teaser = this.containsText(product.description?.short || '') ? product.description?.short : '';

    let variant: any = {
      ...product,
      hasDiscount: !!product.originalPrice,
      teaser,
      image: product.img
    };

    if (!pack)
      if (!attr || product.variantId) {
        this.outShowDiscountTag.emit(variant.hasDiscount && variant.isOnSale);
        return { ...variant, isALaCarte: variant.isALaCarte ?? true };
      }

    const stockVariant = pack ?
      this.#productCardV2Service.getVariantFromPackage(this.stock(), this.productId(), this.selectedAttribute()) :
      this.#productCardV2Service.getVariant(this.stock(), this.productId(), this.selectedAttribute());

    if (pack?.quantity >= 0) {
      variant.price = pack.price * pack.quantity;
      variant.originalPrice = pack.originalPrice * pack.quantity;
      variant.hasDiscount = pack.originalPrice;
      variant.isOnSale = pack.isOnSale ?? variant.isOnSale;
    }

    if (attr?.value) {
      variant = {
        variantId: stockVariant?.variantId,
        ...attr.value,
        hasDiscount: !!attr.value?.originalPrice,
        teaser
      }

    }

    this.outShowDiscountTag
      .emit(variant.hasDiscount && variant.isOnSale);

    this.outSelectedVariant.emit({ ...variant });
    return { ...variant, isALaCarte: variant.isALaCarte ?? true }
  }

  private getOrderProductsMapByKey(productKeyType: string, mapKey: string, sourceOrder: any,) {
    if (!sourceOrder || !sourceOrder?.products?.[productKeyType]?.length)
      return new Map()

    const orderProductsMap = arrayToMap(mapKey, sourceOrder.products[productKeyType]);

    return orderProductsMap;
  }

  private containsText(htmlString: string) {
    // Parse the HTML string into a DOM object
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    if (!doc?.body?.textContent) return false;
    // Get the text content of the parsed document
    const textContent = doc.body.textContent.trim();

    // Check if the text content is empty
    return textContent.length > 0;
  }

  private async hasSkippedOrder(toFlip: boolean = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.odooOrder()?.isSkipped) {
        this.#modalService.openModal(ModalContentTypes.CONFIRMATION, {
          title: `You've skipped the week`,
          textContent: `You want to add products to your order but you skipped this week's delivery`,
          confirmButtonText: 'Unskip this week',
        }, { size: 'md' }).closed
          .subscribe((res: { confirm: boolean } | null) => {
            if (!res?.confirm) return resolve(true);

            const args = {
              orderId: this.odooOrder()?.id,
              isSkipping: false,
              donationAmount: 0,
            }

            this.#orderService.skipOrder(args, false).pipe(delay(toFlip ? 1 : 1000)).subscribe(() => resolve(false))
          })
      } else
        return resolve(false);
    })
  }

  openModalCheckAddress() {
    this.cardType.set(CardTypes.main);
    let data: ModalContentData = {
      title: 'Choose your delivery preference',
      textContent:
        `Before you start shopping, let's make sure you're located near one of our delivery routes or pick up locations`,
      closeable: false,
    };

    if (this.#activeModal.hasOpenModals()) this.#activeModal.dismissAll();

    this.#modalService.openModal(ModalContentTypes.CHECK_ADDRESS, data, undefined);
  }

  #setUpCarouselStock() {
    combineLatest([this.#stockValidation$, this.#hasStockValidationPerQuantity$]).pipe(
      tap(([stockValidation, hasStockValidationPerQuantity]) => {
        if (!this.card()?.product?.id) return;
        const oos = stockValidation && !hasStockValidationPerQuantity
        this.isOutOfStock.set(oos);
        if (!oos) return;
        const currentHiddenProducts: number[] = [...this.#signalsStoreService.hiddenCarouselProducts()];
        const variantId = this.product()?.variantId;
        if (variantId)
          currentHiddenProducts.push(variantId);
        this.#signalsStoreService.hiddenCarouselProducts.set([...currentHiddenProducts]);
      })
    ).subscribe();
  }

  async addOrSubscribeFromFatherComponent(event: any) {

    const hasSkippedOrder = await this.hasSkippedOrder();
    if (hasSkippedOrder) return;

    if (!this.stock().size || this.isOutOfStock() || this.isOutOfStockPerQuantity())
      return;

    if (this.selectedAttributeCardConfig().isSubscription) {

      if (this.marketStatus().isOpen && this.selectedAttributeCardConfig().isALaCarte)
        this.addProductToCart(false, event);
      else {
        if (!this.existsProductAsSubscription()) {
          this.validateProductAndSetUpVariant();
          this.addProductToCart(true, event);
        }
      }

    } else if (this.selectedAttributeCardConfig().isALaCarte)
      this.addProductToCart(false, event);
  }

  #setUpProductDetails(product: Product) {
    // Set default selected package and attribute:
    if (product.attributes?.length)
      this.selectedAttribute.set(product.attributes[0]);
    if (product.packages?.length)
      this.selectedPackage.set(product.packages[0])
    this.product.set(product);
    const stockMap = arrayToMapMultiKey(['productId', 'attribute.id', 'attribute.value.id'], product.stockInfo);
    this.stock.set(stockMap);
    this.card.set({ ...this.card(), product });
    this.#setupProductStock(product, stockMap);
    this.cardType.set(this.cardTypes.main);
    if (product?.bundle?.items?.length) this.#getStockForBundleItems(product.bundle.items);
    this.#setUpCarouselStock();
  }

  #setupProductStock(product: Product, stockMap: Map<number | string, any>) {
    // It's a variant
    if (product?.variantId) {
      if (product?.attributes?.[0])
        this.selectedAttribute.set(product.attributes[0]);
      this.productAttributes = product?.attributes ?? [];
      return
    };

    const attributes = product?.attributes;
    if (attributes) {
      this.productAttributes = attributes;
      const productHasVariants = !!attributes?.length && !!attributes?.[0].values.length;
      if (productHasVariants) {
        let values = attributes[0].values;
        if (!product.allowVariantsWithoutStock) {
          values = values.filter((value: any) => {
            const key = `${product.id}_${attributes[0].id}_${value.id}`;
            const stock = stockMap.get(key);
            if (!stock) return;

            if (stock.stock <= 0) {
              let oosAfterQuantityChange = (stock.stock - this.newQuantity()) < stock.limit;
              if (stock.limit === 0 || oosAfterQuantityChange) {
                return false;
              }
            }
            return true;
          });
        }

        this.productAttributes[0].values = values;
        if (!values.length) {
          this.allVariantsOutOfStock = true;
          this.selectedAttribute.set(null);
          return;
        }
        const attr = attributes[0];
        const firstFav = values.find((v: ProductAttributeValues) => v.fav);
        const selectedAttribute = {
          id: attr.id,
          name: attr.name,
          value: firstFav ?? values[0]
        };

        this.selectedAttribute.set(selectedAttribute);

      }

      return;
    }

    const packages = product?.packages;
    if (packages?.length) {
      const key = product.id;
      const stock = stockMap.get(key);
      if (!stock) return;

      let packages = product.packages?.filter((value: any) => {
        let oosAfterQuantityChange = (stock.stock - (this.newQuantity() * value.quantity)) < stock.limit;
        if (oosAfterQuantityChange) {
          return false;
        }
        return true;
      }) || []

      product.packages = packages;
      if (!packages.length) {
        this.allVariantsOutOfStock = true;
        this.selectedPackage.set(null);
        return;
      }

      const selectedPackage = packages[0];
      const firstDiscountPackage = packages.find(p => p.isOnSale);
      this.selectedPackage.set(firstDiscountPackage ?? selectedPackage);
    }

  }


  #setUpAvailableQuantities() {
    let available = this.totalStockAvailable();

    if (available > 10) available = 10;

    const quantities = []

    if ((available) <= 10) {
      for (let index = 1; index <= available; index++) {
        quantities.push({ val: index, name: (index === 10 ? '+10' : index) });
      }
    }

    return quantities;
  }

  #setUpTotalStockAvailable() {
    const attr = this.selectedAttribute(); // as a trigger
    const pack = this.selectedPackage();

    const product = this.product();
    if (!(product?.checkStock)) return 100;

    if (product?.bundle?.items.length) {
      // Get stock based on bundle items:
      const mapBundleStock = this.mapBundleStockSignal();
      if (!mapBundleStock.size) return 0;

      const itemsArray = Array.from(mapBundleStock.values());
      const minStockItem = itemsArray.reduce((min, item) =>
        item.stock < min.stock ? item : min
      );

      if (!minStockItem) return 0;

      const bundleItemInfo: BundleItems | any = product.bundle.items.find((i: any) => i.id === minStockItem.variantId);
      if (!bundleItemInfo) return 0;
      return Math.floor(((minStockItem.stock + (minStockItem.limit * -1)) / (bundleItemInfo.packageQty ?? 1)));

    } else {
      const stockVariant = pack ?
        this.#productCardV2Service.getVariantFromPackage(this.stock(), this.productId(), this.selectedAttribute()) :
        this.#productCardV2Service.getVariant(this.stock(), this.productId(), this.selectedAttribute());
      if (!stockVariant) return 0;
      return stockVariant.stock + (stockVariant.limit * -1);
    }
  }

  #cardResumeTransform() {
    const value = this.cardResume();
    const productId = value.product?.id || value.productDetails?.id || null;
    if (productId)
      this.productId.set(productId);
    if (value.productDetails)
      this.#setUpProductDetails(value.productDetails);
    this.newQuantity.set(1);
    if (value.settings.isPlaceHolder)
      this.cardType.set(this.cardTypes.resume);
    else if (value.settings?.isSummary)
      this.cardType.set(this.cardTypes.summary);

    return {
      orderId: value?.orderId,
      product: { ...this.defaultCard?.product, ...value?.product },
      settings: { ...this.defaultCard.settings, ...value?.settings },
    } as ProductCardResume
  }

  #existsInOrder() {
    const attr = this.selectedAttribute();
    const pack = this.selectedPackage();
    const firebaseOrder = this.firebaseOrder();
    const odooOrder = this.odooOrder();

    const defaultRes = { common: false, subscription: false, totalQuantity: 0 };

    const stockVariant = pack ?
      this.#productCardV2Service.getVariantFromPackage(this.stock(), this.productId(), this.selectedAttribute()) :
      this.#productCardV2Service.getVariant(this.stock(), this.productId(), this.selectedAttribute());

    if (!stockVariant) return defaultRes;

    // Check if the product exists as subscription or a la carte in firebse or odoo order:
    const subscriptionFBOrderProductsMap = this.getOrderProductsMapByKey('subscription', 'variant.variantId', firebaseOrder)
    const subscriptionOdooOrderProductsMap = this.getOrderProductsMapByKey('subscription', 'variantId', odooOrder)
    const oneTimeFBOrderProductsMap = this.getOrderProductsMapByKey('common', 'variant.variantId', firebaseOrder)
    const oneTimeOdooOrderProductsMap = this.getOrderProductsMapByKey('common', 'variantId', odooOrder)

    let common = false;
    let subscription = false;
    let totalQuantity = 0;

    if (stockVariant) {
      common = oneTimeFBOrderProductsMap.has(stockVariant.variantId) || oneTimeOdooOrderProductsMap.has(stockVariant.variantId);
      subscription = subscriptionFBOrderProductsMap.has(stockVariant.variantId) || subscriptionOdooOrderProductsMap.has(stockVariant.variantId);
    }

    // One time
    if (oneTimeFBOrderProductsMap.has(stockVariant.variantId))
      totalQuantity += oneTimeFBOrderProductsMap.get(stockVariant.variantId).quantity;
    else if (oneTimeOdooOrderProductsMap.has(stockVariant.variantId))
      totalQuantity += oneTimeOdooOrderProductsMap.get(stockVariant.variantId).quantity;

    // Subscriptions
    if (subscriptionFBOrderProductsMap.has(stockVariant.variantId))
      totalQuantity += subscriptionFBOrderProductsMap.get(stockVariant.variantId).quantity;
    else if (subscriptionOdooOrderProductsMap.has(stockVariant.variantId))
      totalQuantity += subscriptionOdooOrderProductsMap.get(stockVariant.variantId).quantity;

    return { common, subscription, totalQuantity }
  }

  #setUpIsPreOrderedVariant() {
    const preOrderedProduct = this.selectedAttributeCardConfig()?.isPreOrdered ?? false;
    const preOrders = this.preOrders();
    if (preOrderedProduct) return true;
    if (!preOrders.length) return false;
    if (!this.validateProductAndSetUpVariant()) return false;
    const variantId = this.#variant.id;
    return preOrders.some(o => o.variant.id === variantId);
  }

  #getStockForBundleItems(items: BundleItems[]) {
    const productsId = items.map((i: any) => {
      return i.productId;
    });

    this.mapBundleStockSignal.set(new Map());

    this.#stockService.getStandAloneStockByProductsId(productsId).pipe(
      tap(res => {
        const mapBundleStock = arrayToMapMultiKey(['variantId'], res);
        if (!mapBundleStock.size) return;

        this.mapBundleStockSignal.set(mapBundleStock);
      })
    ).subscribe();
  }

  #isBundleOutOfStock() {
    const productQtty = this.newQuantity() ?? 1;
    const product = this.product();
    if (!product) return;
    const items: BundleItems[] = product?.bundle?.items || [];
    const checkStock = product.checkStock ?? false;
    if (!items.length || !checkStock) return false;

    const mapBundleStock = this.mapBundleStockSignal();
    if (!mapBundleStock.size) return false;

    let response = false;
    let hasNotify = false;

    for (const item of items) {
      const { id: variantId, quantity, packageQty } = item;
      const stock = mapBundleStock.get(variantId);
      if (!stock) {
        response = true;
        hasNotify = true;
        break;
      }

      if (stock.stock <= 0) {
        const qtty = quantity * (packageQty ?? 1) * productQtty;
        let oosAfterQuantityChange = (stock.stock - qtty) < stock.limit;
        if (stock.limit === 0 || oosAfterQuantityChange) {
          response = true;
          hasNotify = stock.hasNotify;
          break;
        }
      }
    }

    this.hasNotify = hasNotify;
    return response;
  }

  openModalWhatsInside(event: any) {
    event.stopPropagation();
    const product = this.card().product;
    if (!product?.bundle) return;
    const startDate = formatDateToReadableString(product.bundle.startDate).mmddyyyyFormat;
    const endDate = formatDateToReadableString(product.bundle.endDate).mmddyyyyFormat;
    this.#modalService.openModal(ModalContentTypes.BOX_DETAIL, {
      title: product.name,
      fullScreenOnMobile: true,
      boxDetail: {
        isSubscription: this.card().product?.isSubscription,
        existsInOrder: this.card().product?.isSubscription ? this.existsInOrder().subscription : this.existsInOrder().common,
        startDate,
        endDate,
        publishedBundles: product?.bundle?.publishedBundles,
        detail: this.card().product?.bundle?.items?.map((e: any) => {
          return {
            img: e.img,
            productName: e.name,
            quantity: e.quantity,
            packageName: e.packageName || e.display || '',
            producer: e.producerName || '',
            price: e.price,
          }
        })
      }
    } as ModalContentData).closed.subscribe(
      (res: { addToCart: boolean }) => {
        if (res.addToCart) {
          if (this.card().product?.isSubscription) {
            if (environment.config.flows.bundles.modifyType === BundleModifyTypes.default) {
              this.flipCardType(this.cardTypes.subscribe)
            } else {
              this.#router.navigate(['/shop/custom-box/' + BundleEditionTypes.subscription + '/' + this.card().product?.id])
            }
          } else {
            this.addProductToCart(false, event);
          }
        }
      }
    )
  }
  //#endregion
}